import numeral from 'numeral';
import short from 'short-uuid';

import { Cart } from '../cart/models/cart.model';
import { CartProduct } from '../cart/models/cart-product.model';
import { CartQuery } from '../cart/models/cart-query.model';
import { CheckoutFormValues } from '../checkout/checkout-form';
import { Category } from '../models';

// eslint-disable-next-line @typescript-eslint/no-require-imports
require('numeral/locales/de');

const setNumeralConfig = () => {
  numeral.locale('de');
  numeral.localeData('de').delimiters.thousands = '.';
  numeral.localeData('de').delimiters.decimals = ',';
};

export const addFormattedEuroPrices = (
  priceA: string,
  priceB: string,
): string => {
  setNumeralConfig();

  const summedUpPrices = numeral(priceA)
    .add(numeral(priceB).value())
    .format('$0.00');

  return summedUpPrices;
};

export const divideFormattedEuroPrice = (
  price: string,
  val: number,
): string => {
  setNumeralConfig();

  const convertedPrice = numeral(price);

  return convertedPrice.divide(val).format('$0.00');
};

export const getFormattedCart = (data: CartQuery): Cart => {
  if (undefined === data || !data?.cart?.contents?.nodes?.length) {
    return undefined;
  }

  const givenProducts = data.cart.contents.nodes;

  // Create an empty object.
  const formattedCart: Cart = {} as unknown as Cart;
  formattedCart.products = [];
  let totalProductsCount = 0;

  for (let i = 0; i < givenProducts.length; i += 1) {
    const givenProduct = givenProducts[i].product;
    const product: CartProduct = {} as unknown as CartProduct;
    const total =
      givenProducts[i].subtotal === givenProducts[i].total // if tax is not added to total price
        ? addFormattedEuroPrices(
            givenProducts[i].subtotal,
            givenProducts[i].subtotalTax,
          )
        : givenProducts[i].total;
    product.productId = givenProduct?.node?.databaseId;
    product.cartKey = givenProducts[i].key;
    product.name = givenProduct?.node?.name;
    product.qty = givenProducts[i].quantity;
    product.price = divideFormattedEuroPrice(total, product.qty);
    product.totalPrice = total;
    product.link = givenProduct?.node?.link;

    // Ensure we can add products without images to the cart
    // TODO: provide placeholder in assets folder
    if (givenProduct?.node?.image) {
      product.image = {
        sourceUrl: givenProduct?.node?.image.sourceUrl,
        srcSet: givenProduct?.node?.image.srcSet,
        title: givenProduct?.node?.image.title,
      };
    } else {
      product.image = {
        sourceUrl: 'https://via.placeholder.com/434',
        srcSet: 'https://via.placeholder.com/434',
        title: givenProduct?.node?.name,
      };
    }

    totalProductsCount += givenProducts[i].quantity;

    // Push each item into the products array.
    formattedCart.products.push(product);
  }

  const subtotal = addFormattedEuroPrices(
    data.cart.subtotal,
    data.cart.subtotalTax,
  );

  formattedCart.totalProductsCount = totalProductsCount;
  formattedCart.totalProductsPrice = data.cart.total;
  formattedCart.shippingCosts = data.cart.shippingTotal;
  formattedCart.subtotalCosts = subtotal;
  formattedCart.totalTax = data.cart.totalTax;

  return formattedCart;
};

/**
 * Get the updated items in the below format required for mutation input.
 *
 * [
 * { "key": "33e75ff09dd601bbe6dd51039152189", "quantity": 1 },
 * { "key": "02e74f10e0327ad868d38f2b4fdd6f0", "quantity": 1 },
 * ]
 *
 * Creates an array in above format with the newQty (updated Qty ).
 *
 */
export const getUpdatedItems = (
  products: CartProduct[],
  newQty: string,
  cartKey: string,
): { key: string; quantity: number }[] => {
  // Create an empty array.
  const updatedItems: { key: string; quantity: number }[] = [];

  // Loop through the product array.
  products.map((cartItem) => {
    // If you find the cart key of the product user is trying to update, push the key and new qty.
    if (cartItem.cartKey === cartKey) {
      updatedItems.push({
        key: cartItem.cartKey,
        quantity: parseInt(newQty, 10),
      });

      // Otherwise just push the existing qty without updating.
    } else {
      updatedItems.push({
        key: cartItem.cartKey,
        quantity: cartItem.qty,
      });
    }
    return null;
  });

  // Return the updatedItems array with new Qtys.
  return updatedItems;
};

export const createCheckoutData = (order: CheckoutFormValues) => {
  const checkoutData = {
    clientMutationId: short().new(),

    billing: {
      firstName: order.firstName,
      lastName: order.lastName,
      address1: order.address1,
      address2: order.address2,
      city: order.city,
      country: order.country,
      state: order.state,
      postcode: order.postcode,
      email: order.email,
      phone: order.phone,
      company: order.company,
    },
    shipping: {
      firstName: order.firstName,
      lastName: order.lastName,
      address1: order.address1,
      address2: order.address2,
      city: order.city,
      country: order.country,
      state: order.state,
      postcode: order.postcode,
      email: order.email,
      phone: order.phone,
      company: order.company,
    },
    shipToDifferentAddress: false,
    paymentMethod: order.paymentMethod,
    isPaid: false,
    // transactionId: "hjkhi", // not required -> more or less for stripe usage or something
  };

  return checkoutData;
};

/**
 * WooCommerce automatically creates a "Unkategorisiert" category which
 * contains all uncategorized products.
 * This default category may be ignored by using this method.
 *
 * @param categories
 * @returns filtered categories
 */
export const ignoreDefaultCategoryInCategoryNodes = (
  categories: Category[],
) => {
  const DEFAULT_CATEGORY = 'Unkategorisiert';

  return (
    categories?.filter((node: Category) => node.name !== DEFAULT_CATEGORY) || []
  );
};

/**
 * Checks if browser is available (needed for SSR)
 * @returns browser status
 */
export const isBrowserAvailable = (): boolean => typeof window !== 'undefined';
